import service_auth from '../../services/auth'
import service_game from '@/services/games'

export const login = async ({commit}, payload) => {
    try{
        let {data} = await service_auth.login(payload)
        if (data.token) {
            commit('setUser', data.user)
            commit('setAccessToken', data.token)
            return Promise.resolve(data)
        } else {
            return Promise.reject({data: {error: 'Ups algo salio mal'}})
        }
    } catch(err) {
        return Promise.reject(err)
    }
}

export const getCategoryRoulette = async ({commit}) => {
    try{
        let {data} = await service_game.getRouletteQuestionCategory();
        commit('getCategoryRoulette', data)
        return Promise.resolve(data)
    }catch(error){
        Promise.reject(error);
    }
}
export const getQuestionBank = async ({commit}) => {
    try{
        let {data} = await service_game.getQuestionBank();
        commit('getQuestionBank', data)
        return Promise.resolve(data)
    }catch(error){
        Promise.reject(error);
    }
}