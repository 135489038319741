export const avatars = (state) => {
    return state.avatars
}
export const meAvatar = (state) => {
    return state.avatars.find(item => item.id == state.avatar_selected)
}
export const status = (state) => {
    return state.status
}

export const users = (state) => {
    return state.users
}

export const getCategories = (state) => {
    return state.categories
}
export const getQuestionBank = (state) => {
    return state.questionBank
}
export const currentUserId = (state) => {
    return state.currentUserId
}
export const currentTimer = (state) => {
    return state.currentTimer
}
export const timeByUser = (state) => {
    return state.timeByUser
}
export const socket = (state) => {
    return state.socket
}


