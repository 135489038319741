import Vue from 'vue'

export const setAvatarSelected = (state, payload) => {
    Vue.set(state, 'avatar_selected', payload)
}
export const setStatus = (state, payload) => {
    Vue.set(state, 'status', payload)
}

export const setUsers = (state, payload) => {
    
    Vue.set(state, 'users', payload)
}

export const getCategoryRoulette = (state, payload) => {
    Vue.set(state, 'categories', payload)
}
export const getQuestionBank = (state, payload) => {
    Vue.set(state, 'questionBank', payload)
}

export const setCurrentUserId = (state, payload) => {
    Vue.set(state, 'currentUserId', payload)
}

export const resetCurrentTimer = (state) => {
    state.currentTimer = state.timeByUser
}

export const decreaseCurrenTimer = (state) => {
    state.currentTimer = state.currentTimer - 1
}

export const startSocket = (state, io) => {
    state.socket =   io('wss://caravana-ws.saa-redunica.com/rulette-game', {
        transports: ['websocket']
    }) 
    // state.socket =   io('ws://localhost:3000/rulette-game', {
    //     transports: ['websocket']
    // })
}