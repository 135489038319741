<script>
import { mapGetters } from 'vuex'
export default {
   
    computed: {
        layout() {
            return this.$route.meta.layout ? `Layout${this.$route.meta.layout}`  : 'div'
        },
        ...mapGetters({
          'appLoading': 'app/loading'
        })
    },
   
}
</script>
<template>
  <div style="height: 100%;">
    <component :is="layout">
      <router-view></router-view>
    </component>
    <LoaderComp v-if="appLoading"/>
  </div>
</template>

<style>
@import './assets/styles/normalize.css';
@import './assets/styles/base.css';
@import './assets/styles/font.css';
@import './assets/styles/global.scss';

html, body {
  height: 100%;
}

.app{
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
